<template>
  <MDBContainer fluid>
    <Header />
    <MDBRow tag="main" class="bg-dark">
      <slot></slot>
    </MDBRow>
    <LazyNeverFooter hydrate-never  />
    <LazyNeverCopyright hydrate-never  />
  </MDBContainer>
</template>

<script setup lang="ts">
import { MDBContainer, MDBRow } from "mdb-vue-ui-kit";

useHead({
  link: [
    { rel: "icon", type: "image/svg+xml", href: "/logo.svg" },
    { rel: "icon", type: "image/png", href: "/logo.png" },
  ],
});
</script>

<style lang="scss" scoped>
main {
  margin-top: 75px;
}
</style>
